import {
    Link
  } from "react-router-dom";
export function JavaBackend() {
    return (
        <div className="container">
            <table>
                <tr>
                    <th>Job Title</th>
                    <td>Java Developer</td>
                </tr>
                <tr>
                    <th>Team</th>
                    <td>DC15 Backend</td>
                </tr>
                <tr>
                    <th>Job Description </th>
                    <td>
                        <p>
                            We are looking for qualified Java developers to join our team. You will be responsible for building the backend of our web applications in different fields, such as: networking, e-learning, logistics...
                        </p>
                        <p>Responsibilities: </p>
                        <ul>
                            <li>Translate application requirements and use cases into functional applications</li>
                            <li>Design, build, and maintain efficient, reusable, and reliable Java code</li>
                            <li>Ensure the best possible performance, quality, and responsiveness of the applications</li>
                            <li>Identify bottlenecks and bugs, and devise solutions to these problems</li>
                            <li>Stay up-to-date on emerging technologies</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th>Required skills/Experience </th>
                    <td>
                        <ul>
                            <li>Proficient in Java with solid understanding of object-oriented programming</li>
                            <li>Familiar with various design and architectural patterns</li>
                            <li>Knowledge of concurrency patterns in Java</li>
                            <li>Familiarity with concepts of RESTful API</li>
                            <li>Experience with Spring framework</li>
                            <li>Familiarity with build tools such as Ant, Maven</li>
                            <li>Problem-solving attitude</li>
                            <li>Team spirit </li>
                        </ul>
                        <p>Having following knowledge is a plus:</p>
                        <ul>
                            <li>Microservices</li>
                            <li>Kafka (or similar messaging framework)</li>
                            <li>Quarkus</li>
                            <li>Docker</li>
                            <li>Familiarity with Agile/Scrum model</li>
                            <li>Having experience of dealing with large systems matters: performance, scalability…</li>
                            <li>Having experience on Cloud environment</li>
                            <li>HTML/CSS/JS</li>
                            <li>NodeJS, Python</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th>Years of experiences</th>
                    <td>1-5</td>
                </tr>
                <tr>
                    <th>Number of resources</th>
                    <td>5</td>
                </tr>
                <tr>
                    <th>Target day </th>
                    <td>31/12/2022</td>
                </tr>
                <tr>
                    <th>Apply </th>
                    <td><Link to="/apply" className="btn btn-primary">Apply now!</Link> Or email to <a href="mailto:ddkhanh@tma.com.vn">Khanh Dao</a></td>
                </tr>
            </table>
        </div>
    )
}