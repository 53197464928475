import {
    Link
  } from "react-router-dom";
export function LeadJava() {
    return (
        <div className="container">
            <table>
                <tr>
                    <th>Job Title</th>
                    <td>Lead Java Developer</td>
                </tr>
                <tr>
                    <th>Team</th>
                    <td>FC Dev</td>
                </tr>
                <tr>
                    <th>Job Description </th>
                    <td>
                        <p>
                            We are looking for qualified Lead Java developers to join our team. You will be evolving in web application development in different fields, such as: networking, e-learning, logistics, Clinical Research...
                        </p>
                        <p>Responsibilities: </p>
                        <ul>
                            <li>Requirement managements, translate application requirements and use cases into functional applications</li>
                            <li>Design, build, and maintain efficient, reusable, and reliable Java code</li>
                            <li>Ensure the best possible performance, quality, and responsiveness of the applications</li>
                            <li>Managing tech team and coordinating with product teams and business teams for on-time delivery</li>
                            <li>Daily management work, coordinate with PM for making the project plan, guiding junior engineers' daily work and doing knowledge sharing</li>
                            <li>Stay up-to-date on emerging technologies</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th>Required skills/Experience </th>
                    <td>
                        <ul>
                            <li>Experience with requirements-management, backlog grooming and leading the development to meet specifications</li>
                            <li>Good understanding of standard Software Engineering processes, Test Engineering & Automation</li>
                            <li>Demonstrable experience mentoring and providing leadership to other developers
                            </li>
                            <li>Strong troubleshooting, tracing and debugging skills
                            </li>
                            <li>Proficient in JVM and Java ecosystem, such as Spring framework/Spring Boot/Spring Cloud</li>
                            <li>Proficient in monitoring/logging/tracing in Java</li>
                            <li>Proficient in common middleware and data storage, such as message queues, memory caches</li>
                            <li>Have the ability to solve complex system problems</li>
                            <li>Have good communication skills </li>
                            <li>Familiarity with build and CI tools: Maven, Jenkins</li>
                        </ul>
                        <p>Having following knowledge is a plus:</p>
                        <ul>
                            <li>Microservices</li>
                            <li>Docker</li>
                            <li>Reactive Programing</li>
                            <li>Familiarity with Agile/Scrum model</li>
                            <li>Having experience of dealing with large systems matters: performance, scalability…</li>
                            <li>Angular</li>
                            <li>SAML 2.0 SSO</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th>Years of experiences</th>
                    <td>Have +5 years of experience in Java software development</td>
                </tr>
                <tr>
                    <th>Number of resources</th>
                    <td>4</td>
                </tr>
                <tr>
                    <th>Target day </th>
                    <td>31/12/2022</td>
                </tr>
                <tr>
                    <th>Apply </th>
                    <td><Link to="/apply" className="btn btn-primary">Apply now!</Link> Or email to <a href="mailto:ddkhanh@tma.com.vn">Khanh Dao</a></td>
                </tr>
            </table>
        </div>
    )
}