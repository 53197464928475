import {
    Link
} from "react-router-dom";
export function Automation() {
    return (
        <div className="container">
            <table>
                <tr>
                    <th>Job Title</th>
                    <td>Web Automation Tester</td>
                </tr>
                <tr>
                    <th>Team</th>
                    <td>DC15 QA</td>
                </tr>
                <tr>
                    <th>Job Description </th>
                    <td>
                        <p>
                            We are looking for qualified Web Automation testers to join our team. You will be responsible for the quality assurance of our web applications in different fields, such as: networking, healthcare, logistics...
                        </p>
                        <p>
                            If you are interested in creating a user-friendly environment, working with up-to-date automation technologies and moving forward in your career, then this job is for you. We expect you to be a tech-savvy professional, who is curious about building end-user perspective, new automation techniques, frameworks and applying them in controlling, ensuring the quality of projects deliveries.
                        </p>
                        <p>Responsibilities: </p>
                        <ul>
                            <li>Analyzing requirements, software specifications to build up corresponding test plans, test cases</li>
                            <li>Automating test plans, test cases via supporting frameworks (e.g. Selenium, Robot, Katalon…)</li>
                            <li>Handling manual test plans, test cases if required</li>
                            <li>Optimizing automation test scripts</li>
                            <li>Collaborate with developers to improve the usability of the product</li>
                            <li>Stay up-to-date on emerging automation technologies</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th>Required skills/Experience </th>
                    <td>
                        <ul>
                            <li>Having experience as Web Automation tester</li>
                            <li>Experience with Selenium or Katalon or equivalent frameworks.</li>
                            <li>Good understanding on testing process, testing life cycle</li>
                            <li>Being able to communicate directly with customer in English</li>
                            <li>Problem-solving attitude</li>
                            <li>Team spirit</li>
                        </ul>
                        <p>Having following knowledge is a plus:</p>
                        <ul>
                            <li>Robot Framework</li>
                            <li>Jenkins</li>
                            <li>Familiarity with Agile/Scrum model</li>
                            <li>Familiarity with collaboration tools such as JIRA, Confluence</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th>Years of experiences</th>
                    <td>1-5</td>
                </tr>
                <tr>
                    <th>Number of resources</th>
                    <td>4</td>
                </tr>
                <tr>
                    <th>Target day </th>
                    <td>31/12/2022</td>
                </tr>
                <tr>
                    <th>Apply </th>
                    <td><Link to="/apply" className="btn btn-primary">Apply now!</Link> Or email to <a href="mailto:ddkhanh@tma.com.vn">Khanh Dao</a></td>
                </tr>
            </table>
        </div>
    )
}