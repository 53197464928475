import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { positions } from '../conf';
import  { Redirect } from 'react-router-dom'

const formHandler = {
    "fullname": { value: e => { return e.target.value }, buildForm: (f, o) => f.append("fullname", o['fullname']) },
    "phone": { value: e => { return e.target.value }, buildForm: (f, o) => f.append("phone", o['phone']) },
    "position": { value: e => { return e.target.value }, buildForm: (f, o) => f.append("position", o['position']) },
    "cvupload": { value: e => { return e.target.files[0] }, buildForm: (f, o) => f.append("cvupload", o['cvupload'], o['cvupload'].name) },
}
export const Apply = () => {
    const [candidate, setCandidate] = useState({position: positions[0].title})
    const [count, setCount] = useState(10);
    const [uploading, setUploading] = useState(false);
    const [done, setDone] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (uploading && count < 99) {
                setCount(prevCount => prevCount + 1);
            } else if (count === 99 || !uploading) {
                clearInterval(intervalId);
            }
        }, 200);
        return () => clearInterval(intervalId);
    }, [uploading, count]);

    const handleInput = (e) => {
        let checker = formHandler[e.target.name];
        if (!checker) return;
        let value = checker.value(e)
        if (!value || value === '') {
            console.log('Value: ', value)
            e.target.reportValidity();
        } else {
            candidate[e.target.name] = value
        }
    }
    const handleSubmit = (e) => {
        if (e.target.reportValidity()) {
            const formData = new FormData();
            for (const handler in formHandler) {
                formHandler[handler].buildForm(formData, candidate)
            }
            setUploading(true)
            axios.post('/apply', formData)
                .then((res) => {
                    setCount(100)
                    setTimeout(()=>{
                        alert("File Upload success");
                        setDone(true);
                        setCandidate({})
                        setUploading(false)
                    }, 1000)
                })
                .catch((err) => {
                    setCandidate({})
                    alert("Something went wrong, we could not upload your resume. Please try again later!");
                    setUploading(false)
                });
        } else {
        }
        e.preventDefault();
    }
    return (
        <div className="d-flex flex-grow-1 justify-content-center container-fluid image-bg h-100">
            {done && <Redirect to="/"></Redirect>}
            {!uploading && <div className="d-flex container justify-content-center">
                <div className="align-self-center row col-md-6 col-xs-12 apply-form ">
                    <h3 className="display-4">Application Form</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label for="fullname">Full Name</label>
                            <input type="text" className="form-control" name="fullname" id="fullname" aria-describedby="fullname"
                                placeholder="Enter your full name" required value={candidate.fullName} onBlur={handleInput} />
                            <small id="fullnamehelp" className="form-text text-muted">Please input your real name, we'll contact you
                                soon!</small>
                        </div>
                        <div className="form-group">
                            <label for="phone">Phone Number</label>
                            <input type="text" className="form-control" name="phone" id="phone" aria-describedby="phone"
                                placeholder="Enter your phone" value={candidate.phone} onChange={handleInput} />
                        </div>
                        <div className="form-group">
                            <label for="position">Apply Position</label>
                            <select className="form-control" id="position" name="position" onChange={handleInput} value={candidate.position}>
                                {
                                    positions.map(pos => {
                                        return <option value={pos.title} key={pos.title}>{pos.title}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="cvupload" className="form-label">Your resume</label>
                            <input className="form-control" type="file" name="cvupload" id="cvupload" required value={candidate.cvupload} onChange={handleInput} />
                        </div>
                        <div className="form-group submit-btn">
                            <div className="d-flex justify-content-end">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            }
            {uploading && <div class="align-self-center col-md-6 col-xs-12 apply-form">
                <h6>Thank you {candidate.fullname}, your resume is being uploaded, please wait for a moment!</h6>
                <div class="progress" style={{ height: "2.2em" }}>
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={count} aria-valuemin="0" aria-valuemax="100" style={{ width: count + "%", fontWeight: "bolder" }}>
                   {count}%
                </div>
            </div>
            </div>
            }
        </div>
    )
}