import {
    Link
  } from "react-router-dom";
import { positions } from "../conf";

export function Home() {
    return (
        {"title": "Backend Developer", "path": "/java"},
        <div className="d-flex flex-grow-1 flex-column h-100 image-bg">
            <div className="trapezoid">
                <span className="container">
                    <p className="row row-cols-1">
                        <div>
                        <div className="display-6 col cnt text-center" style={{marginTop: "10px"}}><img src="dc15-logo.png" style={{maxWidth: "1em", margin: "0 3px 10px 0"}}></img>Job House</div>
                        </div>
                        <h4 className="display-7 col cnt text-center">We're looking for qualified candiates to join with us for the
                            following possitions</h4>
                    </p>
                </span>
            </div>
            <div className="flex-grow-1 container h-100 board shadow-lg p-3 mb-3 rounded">
                <div className="row">
                    {
                        positions.map(pos =>{
                            return (
                                <Link className="btn col-md-6 col-xs-12 job job-info" to={pos.path}>
                                <div className="card alert-warning d-flex align-items-center">
                                    <h5 className="card-body display-6 d-flex align-items-center text-center ">{pos.title}</h5>
                                </div>
                                </Link>
                            )
                        })
                    }                   
                </div>
            </div>
        </div>
    )
}