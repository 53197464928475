import logo from './logo.svg';
import './App.css';
import {
  Link,
  Route, Switch
} from "react-router-dom";
import { Home } from './pages/home';
import { JavaBackend } from './pages/java';
import { Frontend } from './pages/frontend';
import { Automation } from './pages/automation';
import { DevOps } from './pages/devops';
import { LeadJava } from './pages/leadjava';
import { Apply } from './pages/apply';
function App() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/java">
        <JavaBackend/>
      </Route>
      <Route path="/frontend">
        <Frontend/>
      </Route>
      <Route path="/automation">
        <Automation/>
      </Route>
      <Route path="/devops">
        <DevOps/>
      </Route>
      <Route path="/java-lead">
        <LeadJava/>
      </Route>
      <Route path="/apply">
        <Apply/>
      </Route>
    </Switch>
  );
}

export default App;
