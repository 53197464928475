import {
    Link
} from "react-router-dom";
export function DevOps() {
    return (
        <div className="container">
            <table>
                <tr>
                    <th>Job Title</th>
                    <td>DevOps Developer</td>
                </tr>
                <tr>
                    <th>Team</th>
                    <td>DC15 DevOps</td>
                </tr>
                <tr>
                    <th>Job Description </th>
                    <td>
                        <p>
                            We are looking for DevOps engineers to help us build functional systems that improve customer experience.
                        </p>
                        <p>
                            DevOps Engineer responsibilities include deploying product updates, identifying production issues and implementing integrations addressing customer needs.
                        </p>
                        <p>
                            Ultimately, you should be able to execute and automate operational processes quickly, accurately and securely.
                        </p>
                        <p>Responsibilities: </p>
                        <ul>
                            <li>Build & maintain CI & CD system of project</li>
                            <li>Implement integrations requested by customers</li>
                            <li>Deploy updates and fixes</li>
                            <li>Build tools to reduce occurrences of errors and improve customer experience</li>
                            <li>Perform root cause analysis for production errors</li>
                            <li>Investigate and resolve technical issues</li>
                            <li>Develop scripts to automate operational processes</li>
                            <li>Design procedures for system troubleshooting and maintenance</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th>Required skills/Experience </th>
                    <td>
                        <ul>
                            <li>Working experience as a DevOps Engineer or similar software engineering role</li>
                            <li>Scripting with either Python or Bash shell => to automate the deployment procedure.</li>
                            <li>System admin knowledge (network and system configuration, security… on Linux environment) => to operate and support.</li>
                            <li>Problem-solving attitude</li>
                        </ul>
                        <p>Having following knowledge is a plus:</p>
                        <ul>
                            <li>AWS (VPC, EC2, SES...)</li>
                            <li>Docker</li>
                            <li>Kubernetes</li>
                            <li>Gitlab CI, Jenkins</li>
                            <li>Configuration management tools: Ansible, Chef…</li>
                            <li>NodeJS</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th>Years of experiences</th>
                    <td>1-5</td>
                </tr>
                <tr>
                    <th>Number of resources</th>
                    <td>5</td>
                </tr>
                <tr>
                    <th>Target day </th>
                    <td>31/12/2022</td>
                </tr>
                <tr>
                    <th>Apply </th>
                    <td><Link to="/apply" className="btn btn-primary">Apply now!</Link> Or email to <a href="mailto:ddkhanh@tma.com.vn">Khanh Dao</a></td>
                </tr>
            </table>
        </div>
    )
}